import React from "react"
import { Link, graphql } from "gatsby"

import Seo from "../components/seo"
import Arrow from "~/images/common_icon_arrow_r.svg";

const PageTemplate = ({ data }) => {
  let page = data.wpPage

  return (
    <>
      <Seo title={page.title} pageUrl={'pages/' + page.slug} />
      <div className="pageComponent">
        <nav className="contentComponent">
          <ul className="breadcrumbComponent mincho">
            <li><Link to="/" itemProp="url">TOP</Link> <span><Arrow /></span></li>
            <li>{page.title}</li>
          </ul>
        </nav>
        <article
          className={page.slug}
          itemScope
          itemType="http://schema.org/Article">
          <header className="titleComponent">
            <h1 className="contentComponent heading with-english">
              {
                page.slug === "faq" ? <span>よくある質問</span> :
                page.slug === "contact" ? <span>お問い合わせ</span> : <></>
              }
              {page.title}</h1>
            <hr className="borderLine"/>
          </header>
          <div className="contentComponent">
            <div dangerouslySetInnerHTML={{__html: page.content}}/>
          </div>
        </article>

      </div>
    </>
  )
}

export const pageQuery = graphql`
  query ($slug: String!) {
    wpPage(slug: {eq: $slug}) {
      id
      slug
      title
      content
      featuredImage {
        node {
          localFile {
            childImageSharp {
              fluid(quality: 100, maxWidth: 4000) {
                ...GatsbyImageSharpFluid_withWebp
              }
            }
          }
        }
      }
    }
  }
`

export default PageTemplate
